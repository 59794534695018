import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { Page } from "../../../components/page/page"
import { PageProps } from "../../../types/page"
import { mapPodcastIndexQueryToView } from "../mappers/podcast-index-mappers"
import { PodcastIndexView } from "../views/podcast-index/podcast-index-view"
import { PodcastIndexQuery } from "../podcast-types"

export const PodcastIndexTemplate: FunctionComponent<
  PageProps<PodcastIndexQuery>
> = ({ data, ...pageProps }) => {
  const { metadata, ...viewProps } = mapPodcastIndexQueryToView(data!)
  const indexPageData = data?.contentfulIndexPage

  const metadataOverrides = {
    title: indexPageData?.metadata?.title || metadata.title,
    description: indexPageData?.metadata?.description || metadata.description,
    openGraphImage:
      indexPageData?.metadata?.image?.fixed?.src || metadata.openGraphImage
  }

  return (
    <Page
      {...pageProps}
      metadata={{ ...metadata, ...metadataOverrides }}
      pageOptions={{ headerVariant: "cream" }}
    >
      <PodcastIndexView {...viewProps} />
    </Page>
  )
}

export default PodcastIndexTemplate

export const query = graphql`
  query PodcastIndex($id: String) {
    contentfulIndexPage(id: { eq: $id }) {
      ...PodcastIndexView
    }
    allContentfulPodcastShow {
      nodes {
        ...PodcastShowCard
      }
    }
  }
`
