import { FluidObject } from "gatsby-image"
import { mapMetadata } from "../../../core/mappers/map-metadata"
import { ContentfulPodcastShow } from "../../../types/graphql"
import { PodcastIndexViewShow } from "../views/podcast-index/podcast-index-view"
import { PodcastIndexQuery, PodcastIndexView } from "../podcast-types"

export const podcastShowsOrderHashMap = {
  "renewing-your-mind": 0,
  "5-minutes-in-church-history-with-stephen-nichols": 1,
  "things-unseen-with-sinclair-ferguson": 2,
  "ask-ligonier": 3,
  "ultimately-with-rc-sproul": 4,
  "open-book-with-stephen-nichols": 5,
  "simply-put": 6,
  "luther-in-real-time": 7,
  "westminster-shorter-catechism": 8,
  "renovando-tu-mente-con-r-c-sproul": 9,
  "5-minutos-en-la-historia-de-la-iglesia-con-stephen-nichols": 10
}

export const mapPodcastShowsToView = (
  shows: ContentfulPodcastShow[]
): PodcastIndexViewShow[] => {
  const orderedShows: PodcastIndexViewShow[] = []
  const unorderedShows: PodcastIndexViewShow[] = []

  // podcastShowsOrderHashMap above uses the slugs and hardcodes
  // an index number in the order that the content stakeholders want
  shows.forEach((show) => {
    const mappedShow = {
      image: show.image.fluid?.src || "",
      imageFluid: show.image.fluid as FluidObject,
      title: show.title || "Missing Title",
      body: show.description.childMarkdownRemark.rawMarkdownBody,
      link: show.websiteUrl || show.slug
    }

    const orderedShowIndex =
      podcastShowsOrderHashMap[
        show.slug as keyof typeof podcastShowsOrderHashMap
      ]

    // if the current show has  'positive' index place it at the correct index in the orderedlist
    if (typeof orderedShowIndex === "number" && orderedShowIndex > -1) {
      orderedShows[orderedShowIndex] = mappedShow

      return
    }

    // otherwise if it cannot find it's ordering index in the hashmap, push it into the unordered list
    unorderedShows.push(mappedShow)
  })

  return [...orderedShows, ...unorderedShows]
}

export const mapPodcastIndexQueryToView = (
  data: PodcastIndexQuery
): PodcastIndexView => {
  const indexPage = data.contentfulIndexPage
  const podcastShows = data.allContentfulPodcastShow.nodes
  const bodyRemark = indexPage?.subtitle?.childMarkdownRemark

  return {
    title: indexPage?.title || "Missing Title",
    body: bodyRemark?.rawMarkdownBody,
    image: indexPage?.headerImage?.fluid,
    shows: mapPodcastShowsToView(podcastShows),
    metadata: mapMetadata(
      indexPage?.metadata,
      {
        title: indexPage?.title,
        description: bodyRemark?.plainText,
        image: indexPage?.headerImage?.fixed?.src
      },
      {
        title: indexPage?.pageTitle
      }
    )
  }
}
