import React, { Fragment, FunctionComponent } from "react"
import { useIntl } from "react-intl"
import { navigate } from "gatsby"
import Image, { FluidObject } from "gatsby-image"
import { CardLearn } from "../../../../components/card/card-learn"
import styles from "./podcast-index.module.scss"
import { Markdown } from "@components/markdown/markdown"

export interface PodcastIndexViewShow {
  image: string
  imageFluid: FluidObject
  title: string
  body: string
  link: string
}

export interface PodcastIndexViewProps {
  title: string
  body?: string
  image?: FluidObject
  shows: PodcastIndexViewShow[]
}

export const PodcastIndexView: FunctionComponent<PodcastIndexViewProps> = ({
  title,
  body,
  image,
  shows
}) => {
  const callout = useIntl().formatMessage({ id: "podcasts-index-card-callout" })

  return (
    <Fragment>
      <header className={styles.header}>
        <div className={styles.headerText}>
          <h1 className={styles.headerTitle}>{title}</h1>
          {body && <Markdown className={styles.headerBody} content={body} />}
        </div>
        {image && <Image className={styles.headerImage} fluid={image} />}
      </header>
      <div className={styles.shows}>
        {shows.map((show, index) => (
          <CardLearn
            {...show}
            key={index}
            size="fluid"
            callout={callout}
            className={styles.show}
          />
        ))}
      </div>
    </Fragment>
  )
}
